<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
          <div>
            <b-card>
                <b-row>
                    <b-col>
                        <ListReportHead :baseUrl="licenseRegistrationServiceBaseUrl" url="/configuration/report-heading/detail" @readyReportHead="inputReportHead" :orgId='3' :address_en="address_en" :address_bn="address_bn" :isadmin="false">
                          {{ $i18n.locale === 'bn' ? 'বিজ্ঞপ্তি' : 'Circulars' }} </ListReportHead>
                    </b-col>
                </b-row>
                <b-row>
                  <b-table-simple bordered hover striped>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('globalTrans.fiscal_year')}}</b-th>
                      <b-td style="width:65%%;" class="text-left">
                        {{ getColumnName($store.state.Portal.commonObj.fiscalYearList, details.fiscal_year_id)}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('globalTrans.office')}}</b-th>
                      <b-td style="width:65%%;" class="text-left">
                        {{ getColumnName($store.state.Portal.commonObj.officeList, details.office_id)}}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('globalTrans.title')}}</b-th>
                      <b-td style="width:65%%;" class="text-left">{{ currentLocale === 'bn' ? details.title_bn : details.title_en }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('li_step.circular_memo_no')}}</b-th>
                      <b-td style="width:65%%;" class="text-left"> {{ details.circular_memo_no }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('li_step.circular_date')}}</b-th>
                      <b-td style="width:65%%;" class="text-left"> {{ details.circular_date | dateFormat }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('li_step.circular_start_date')}}</b-th>
                      <b-td style="width:65%%;" class="text-left"> {{ details.start_date | dateFormat }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('li_step.circular_end_date')}}</b-th>
                      <b-td style="width:65%%;" class="text-left"> {{ details.end_date | dateFormat }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('globalTrans.description')}}</b-th>
                      <b-td style="width:65%%;" class="text-left">
                        <div v-if="currentLocale === 'en'" v-html="details.description_en"></div>
                        <div v-if="currentLocale === 'bn'" v-html="details.description_bn"></div>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('externalLrcpn.type')}}</b-th>
                      <b-td style="width:65%%;" class="text-left">
                        <span style="width: 50px !important" class="badge pay-status badge-primary w-100 text-white font-weight-bold" v-if="details.manual === 0">
                          {{ $t('globalTrans.new') }}
                        </span>
                        <span style="width: 50px !important" class="badge pay-status badge-success w-100 text-white font-weight-bold" v-else-if="details.manual === 1">
                          {{ $t('globalTrans.renew') }}
                        </span>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th style="width:35%;" scope="row" class="text-left">{{ $t('globalTrans.download')}}</b-th>
                      <b-td style="width:65%%;" class="text-left">
                        <a target="_blank" v-if="details.attachment"
                            :href="licenseRegistrationServiceBaseUrl + 'download-attachment?file=uploads/circular-publications/original/' + details.attachment">{{ $t('globalTrans.download')}} <i
                              class="ri-download-cloud-fill"></i> </a>
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                </b-row>
            </b-card>
          </div>
      </template>
    </iq-card>
  </b-container>
</template>
<script>
import { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadLrcpnPortal.vue'
// import ReportHeading from '../../components/ReportHeadingDealerPortal.vue'
// import ExportPdf from './export_pdf_details'
export default {
name: 'Details',
props: ['id', 'item'],
components: {
  ListReportHead
},
data () {
  return {
    licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
    valid: null,
    errors: [],
    address_en: '',
    address_bn: '',
    form: []
  }
},
created () {
  this.details = this.item
  const officeInfo = this.$store.state.Portal.commonObj.officeList.find(item => item.value === this.details.office_id)
  if (typeof officeInfo !== 'undefined') {
    this.address_en = officeInfo.text_en
    this.address_bn = officeInfo.text_bn
  }
},
computed: {
  menuId () {
    return this.$route.params.id
  },
  menuInfoList () {
    const menuId = this.$route.params.id
    return this.$store.state.licenseRegistration.commonObj.reportMenuList.find(item => item.menu_id === parseInt(menuId))
  },
  currentLocale () {
    return this.$i18n.locale
  }
},
methods: {
  getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        } else {
          return obj.text_en
        }
      } else {
          return ''
      }
    },
  inputReportHead (data) {
      if (data.orgName) {
        this.reportHeadData = data
      } else {
        if (this.$i18n.locale === 'bn') {
          this.reportHeadData.orgName = 'কৃষি মন্ত্রণালয়'
          this.reportHeadData.address = 'বিল্ডিং নং ৪, সচিবালয়, আব্দুল গণি রোড, ঢাকা'
        } else {
          this.reportHeadData.orgName = 'Ministry of Agriculture'
          this.reportHeadData.address = 'Building no 4, Secretariat, Abdul Gani Rd, Dhaka'
        }
      }
    },
   EngBangNum (n) {
    if (this.$i18n.locale === 'bn') {
      return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
    } else {
      return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
    }
  }
  // pdfExport () {
  //   const reportTitle = this.$t('li_step.circular')
  //   ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', 3, reportTitle, this, this.details)
  // }
}
}
</script>
<style scoped>
.fontSize{
  text-align: justify;
  text-justify: inter-word;
}
.titlebold{
  font-weight: bold;
}
.titleLighter {
  font-weight: lighter;
  font-size: 20px;
}
.tagBorder {
  border: 1px solid black;
  padding: 10px;
  margin-right: -9px
}
</style>
