<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ checkBn ? headerText.text_bn : headerText.text_en }}</h2>
<!--      <h2 class="cover_title">{{ checkBn ?  getParentName($route.query.service_type, parentId).name_bn : getParentName($route.query.service_type, parentId).name }}</h2>-->
      <img src="../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item"><a href="javascript:" @click="$router.go(-1)">{{ $t('portal.service_details') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('externalIncentive.circular_list') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <div class="service_details">
              <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('portal.badc_circular_list') }}</h4>
                </template>
                <template v-slot:body>
                <b-row>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Fiscal Year" vid="fiscal_year_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('globalTrans.fiscal_year') }}
                            </template>
                            <b-form-select
                            plain
                            v-model="search.fiscal_year_id"
                            :options="fiscalYearList"
                            id="fiscal_year_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="6" md="6">
                    <ValidationProvider name="Circular Memo No" vid="circular_memo_no" >
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('li_step.circular_memo_no') }}
                        </template>
                        <b-form-input
                          plain
                          v-model="search.circular_memo_no"
                          id="circular_memo_no"
                          placeholder="Circular Memo No"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                    <h4 class="mt-3" style="font-size: 26px;">
                      <slot v-if="$i18n.locale === 'bn'">
                        আপনার যদি ইতিমধ্যে ম্যানুয়াল লাইসেন্স থেকে থাকে তাহলে নবায়নের এর জন্য এখানে <a href="javascript:" style="color: red" @click="setLicenseManualServiceUrl()"> ক্লিক </a> করুন |
                      </slot>
                      <slot v-else>
                        If You Have Manual License Please click, <a href="javascript:" style="color: red" @click="setLicenseManualServiceUrl()"> Here </a>
                      </slot>
                    </h4>
                  </b-col>
                </b-row>
                </template>
            </iq-card>
            <b-row>
                <b-col md="12">
                    <iq-card>
                        <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('portal.badc_circular_list') }}</h4>
                        </template>
                        <template v-slot:body>
                        <b-overlay :show="loading">
                          <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                <template v-slot:cell(index)="data">
                                  {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(circular_memo_no)="data">
                                  {{ EngBangNum(data.item.circular_memo_no )}}
                                </template>
                                <template v-slot:cell(circular_date)="data">
                                  {{ data.item.circular_date | dateFormat }}
                                </template>
                                <template v-slot:cell(start_date)="data">
                                  {{ data.item.start_date | dateFormat }}
                                </template>
                                <template v-slot:cell(end_date)="data">
                                  {{ data.item.end_date | dateFormat }}
                                </template>
                                <template v-slot:cell(manual)="data">
                                  <span class="badge pay-status badge-primary w-100 text-white font-weight-bold" v-if="data.item.manual === 0">
                                    {{ $t('globalTrans.new') }}
                                  </span>
                                  <span class="badge pay-status badge-success w-100 text-white font-weight-bold" v-else-if="data.item.manual === 1">
                                    {{ $t('globalTrans.renew') }}
                                  </span>
                                </template>
                                <!-- <template v-slot:cell(status)="data">
                                  <span class="badge badge-warning" v-if="data.item.status == 1">{{$t('globalTrans.pending')}}</span>
                                  <span class="badge badge-success" v-else-if="data.item.status == 2">{{$t('globalTrans.published')}}</span>
                                </template> -->
                                <template v-slot:cell(action)="data">
                                  <b-button :title="$t('globalTrans.details')" v-b-modal.modal-form-details class="mr-1 mt-1 mb-1" size="sm" @click="details(data.item)"><i class="fa fa-eye m-0"></i></b-button>
                                  <a class="btn btn-primary" href="javascript:" @click="setServiceUrl(data.item)" size="sm" v-if="data.item.end_date >= current_date">
                                      {{ $t('externalIncentive.apply') }}
                                  </a>
                                </template>
                              </b-table>
                            </b-col>
                          </b-row>
                        </b-overlay>
                        </template>
                    </iq-card>
                </b-col>
            </b-row>
            <b-modal id="modal-form-details" size="lg" :title="detailsTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
              <Details :id="editItemId" :key="editItemId" :item="item" />
            </b-modal>
            </div>
          </b-col>
        </b-row>
        <div class="service_btn_wrapper">
            <a href="javascript:" @click="$router.go(-1)" class="service_btn back_btn"><i class="fas fa-arrow-left"></i> {{ $t('portal.go_back') }}</a>
            <!-- <router-link v-if="service.pr_apply_btn" :to="service.service_url" class="service_btn send_btn"><i class="fas fa-paper-plane"></i> {{ $t('portal.apply_now') }}</router-link> -->
            <a href="javascript:void" v-if="service.pr_apply_btn" @click="setServiceUrl(service.service_url)" :to="{ path: '/incentive-farmer/subsidy-application-form?circularId=' + data.item.id }" class="service_btn send_btn"><i class="fas fa-paper-plane"></i> {{ $t('portal.apply_now') }}</a>
        </div>
        <b-row v-if="service.pr_rating">
          <b-col>
            <div class="rating_review_wrapper">
              <b-card header="Ratings & Reviews of Irrigation Scheme" header-tag="header">
                <b-card-text>
                  <b-row>
                    <b-col sm="2">
                      <div class="text-center">
                        <!-- <h4 class="rating_title">Ratings</h4> -->
                        <div class="rating_circle my-2">4.1<sub style="color: #9e9e9e;">/5</sub></div>
                        <div class="stars-outer">
                          <div class="stars-inner"></div>
                        </div>
                        <div style="color: #9e9e9e;"><small>440 Ratings</small></div>
                      </div>
                    </b-col>
                    <b-col sm="4">
                      <div class="progress mt-4" style="height:10px">
                        <div class="progress-bar dark" style="width:70%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:10%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:10%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:5%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:5%;height:10px"></div>
                      </div>
                    </b-col>
                    <b-col sm="1" class="d-none d-sm-block">
                      <div class="row">
                        <h6 class="rating_text rating_text_common">5</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">4</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">3</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">2</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">1</h6>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
                <b-card-text>
                  <div class="reviews_wrapper">
                    <h5 class="reviews_title">Reviews</h5>
                    <div class="review_item">
                      <div class="rating_counter">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p class="date">11 Jul 2021</p>
                      <p class="user_wrapper">by Md. Tawhid <span class="verified"><i class="fas fa-user-shield"></i> Verified Purchase</span></p>
                      <p class="comment">ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই। ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই।</p>
                    </div>
                    <div class="review_item">
                      <div class="rating_counter">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p class="date">11 Jul 2021</p>
                      <p class="user_wrapper">by Md. Tawhid <span class="verified"><i class="fas fa-user-shield"></i> Verified Purchase</span></p>
                      <p class="comment">ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই। ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই।</p>
                    </div>
                    <div class="review_item">
                      <div class="rating_counter">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p class="date">11 Jul 2021</p>
                      <p class="user_wrapper">by Md. Tawhid <span class="verified"><i class="fas fa-user-shield"></i> Verified Purchase</span></p>
                      <p class="comment">ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই। ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই।</p>
                    </div>
                  </div>
                </b-card-text>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import Details from './Details'
import RestApi, { licenseRegistrationServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
const today = new Date().toISOString().substr(0, 10)
export default {
  mixins: [ModalBaseMasterList],
  props: ['serviceUrl', 'headerItem'],
  components: {
    Details
  },
  data () {
    return {
        current_date: today,
        loading: false,
        headerText: { text_en: '', text_bn: '' },
        service: {},
        search: {
          fiscal_year_id: 0,
          circular_memo_no: ''
        },
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1 },
          { labels: 'globalTrans.fiscal_year', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2 },
          { labels: 'globalTrans.office', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2 },
          { labels: 'li_step.title', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2 },
          { labels: 'li_step.circular_memo_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3 },
          { labels: 'li_step.circular_date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4 },
          { labels: 'li_step.circular_start_date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5 },
          { labels: 'li_step.circular_end_date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6 },
          { labels: 'externalLrcpn.type', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6 },
          { labels: 'globalTrans.action', class: 'text-center', sortable: true, stickyColumn: false, show: '1', order: 9 }
        ],
        item: '',
        parentId: ''
    }
  },
  computed: {
    detailsTitle () {
      return this.$t('li_step.circular') + ' ' + this.$t('globalTrans.details')
    },
    fiscalYearList: function () {
      return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0).map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    },
    subsidyCircularTypeList: function () {
      return this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.subsidyCircularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'office_bn' },
          { key: 'title_bn' },
          { key: 'circular_memo_no' },
          { key: 'circular_date' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'manual' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'office' },
          { key: 'title_en' },
          { key: 'circular_memo_no' },
          { key: 'circular_date' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'manual' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  created () {
    this.loadData()
    this.setComp()
    this.setHeaderText()
  },
  methods: {
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    async loadData () {
        this.loading = true
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        await RestApi.getData(licenseRegistrationServiceBaseUrl, '/portal/license-circular/list', params).then(response => {
        if (response.success) {
          setTimeout(async () => {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
            this.loading = false
          }, 8000)
        } else {
          this.loading = false
          this.$store.dispatch('setList', [])
        }
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0)
      return data.map(item => {
        const fiscalObject = fiscalYearList.find(fiscal => fiscal.value === item.fiscal_year_id)
        const fiscalyearData = {
          fiscal_year: fiscalObject !== undefined ? fiscalObject.text_en : '',
          fiscal_year_bn: fiscalObject !== undefined ? fiscalObject.text_bn : ''
        }
        const officeObj = this.$store.state.Portal.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
        const officeData = {}
        if (typeof officeObj !== 'undefined') {
          officeData.office = officeObj.text_en
          officeData.office_bn = officeObj.text_bn
        } else {
          officeData.office = ''
          officeData.office_bn = ''
        }
        return Object.assign({}, item, fiscalyearData, officeData)
      })
    },
    setServiceUrl (item) {
      const url = '/lrcpn/application-form?step_id=33&org_id=3&service_id=19&circularId=' + item.id + '&office_type_id=' + item.office_type_id + '&office_id=' + item.office_id + '&manual=' + item.manual
      this.$store.dispatch('Portal/setServiceUrl', {
        externalPanelServiceUrl: url,
        externalPanelCurrentServiceUrl: url
      })
      // this.$store.dispatch('Portal/setServiceUrl', {
      //   externalPanelServiceUrl: this.serviceUrl,
      //   externalPanelCurrentServiceUrl: this.serviceUrl,
      //   subsidyCircularId: circularId
      // })
      this.$router.push('/auth/login')
    },
    setLicenseManualServiceUrl () {
      const url = '/lrcpn/application-form?step_id=33&org_id=3&service_id=19&manual=1'
      this.$store.dispatch('Portal/setServiceUrl', {
        externalPanelServiceUrl: url,
        externalPanelCurrentServiceUrl: url
      })
      this.$router.push('/auth/login')
    },
    async loadServiceData () {
      // if (this.$route.query.service_id) {
        this.loading = true
        const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/service/show', { id: this.$route.query.service_id })
        this.loading = false
        if (result.success) {
          this.service = result.data
          this.setSubsidyCircular(this.service.component_id)
        }
      // } else {
      //   this.$router.push('/portal/home')
      // }
    },
    setComp () {
      if (this.$route.query.customer_type_id) {
        this.parentId = this.$route.query.customer_type_id
      } else if (this.$route.query.service_category_id) {
        this.parentId = this.$route.query.service_category_id
      } else {
        this.parentId = this.$route.query.org_id
      }
    },
    setHeaderText () {
      if (this.headerItem === undefined) {
        this.headerText = {
          text_en: '',
          text_bn: ''
        }
        return
      }
      if (this.headerItem.type === 'orgList') {
        const org = this.$store.state.Portal.orgList.find(item => item.id === this.headerItem.id)
        this.headerText = {
          text_en: typeof org !== 'undefined' ? org.abbreviation : '',
          text_bn: typeof org !== 'undefined' ? org.abbreviation_bn : ''
        }
      } else if (this.headerItem.type === 'customerTypeList') {
        const customerType = this.$store.state.Portal.customerTypeList.find(item => item.id === this.headerItem.id)
        this.headerText = {
          text_en: customerType.name,
          text_bn: customerType.name_bn
        }
      } else if (this.headerItem.type === 'categoryList') {
        const category = this.$store.state.Portal.categoryList.find(item => item.id === this.headerItem.id)
        this.headerText = {
          text_en: category.name,
          text_bn: category.name_bn
        }
      } else {
        this.headerText = {
          text_en: 'সকল সেবা',
          text_bn: 'All Services'
        }
      }
    },
    getParentName (parentType, Id) {
      if (parentType) {
        if (parentType === 'allService') {
          return { name: this.$t('portal.all_service'), name_bn: this.$t('portal.all_service') }
        } else {
          const tmpData = this.$store.state.Portal[parentType]
          if (typeof tmpData === 'undefined') {
            return ''
          } else {
            const data = tmpData.find(item => item.id === parseInt(Id))
            if (typeof data === 'undefined') {
              return ''
            } else {
              if (parentType === 'orgList') {
                return Object.assign(data, { name: data.abbreviation, name_bn: data.abbreviation_bn })
              } else {
                return data
              }
            }
          }
        }
      }
    }
  }
}
</script>
